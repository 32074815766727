.confirmation {
    &__container {
        padding: 40px 32px;

        @include mobile() {
            padding: 40px 16px;
        }

        &__logo {
            text-align: center;

            img {
                width: 110px;
                height: 48px;
            }
        }

        &__enroll {
            text-align: center;
            margin-top: 24px;

            img {
                width: 47px;
                height: 48px;
            }
        }

        &__heading {
            text-align: center;
            margin-top: 24px;
            @extend %typography-h2;
        }

        &__heading-sign-in {
            width: 402px;
            text-align: center;
            margin: 0 auto;
            margin-top: 24px;
            @extend %typography-h2;

            span {
                font-style: italic;
            }

            @include mobile() {
                width: 100%;
            }
        }

        &__heading-manual {
            width: 464px;
            text-align: center;
            margin: 0 auto;
            margin-top: 24px;
            @extend %typography-h2;

            span {
                font-style: italic;
            }

            @include mobile() {
                width: 100%;
            }
        }

        &__sub-heading {
            margin-top: 16px;
            border-radius: 12px;
            padding: 24px;

            @include theme {
                background-color: variable("secondary-contrast-text");
            }

            @include mobile() {
                padding: 16px;
            }

            &__text-heading {
                text-align: center;
                @extend %typography-h2;

                @include theme {
                    font-size: 20px;
                    line-height: 28px;
                }
            }

            &__text {
                text-align: center;
                margin-top: 16px;
                @extend %typography-subtitle2;

                @include theme {
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        }

        &__sub-heading-manual {
            margin-top: 16px;
            border-radius: 12px;
            padding: 24px 16px;

            @include theme {
                background-color: variable("secondary-contrast-text");
            }

            &__text-heading {
                text-align: center;
                @extend %typography-h2;

                @include theme {
                    font-size: 20px;
                    line-height: 28px;
                }
            }

            &__text {
                margin-top: 16px;
                @extend %typography-subtitle2;

                ul {
                    padding-left: 16px;
                }

                @include theme {
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        }

        &__secure {
            margin-top: 24px;
            display: flex;
            align-items: center;
            justify-content: center;

            .secured_message {
                margin: 4px;
                @extend %typography-subtitle2;

                @include theme {
                    line-height: 16px;
                }
            }

            .lock-icon {
                margin-bottom: 2px;
            }
        }

        &__app-btn {
            margin-top: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            grid-column-gap: 12px;

            img {
                border-radius: 8px;
            }
        }

        &__close-button {
            text-align: end;
            img {
                width: 16px;
                height: 16px;
            }
        }
    }
}
