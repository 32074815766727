//
// Layout
// --------------------------------------------------
// Specific to project -- ex. home page wrapper, contact wrapper, blog wrapper etc
//
.app-container {
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin: 0 auto;

    @include mobile() {
        width: 100%;
    }
}

html,
body {
    margin: 0;
}
