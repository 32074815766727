@import "../themes/default.module";

%typography-p {
    @include theme {
        font-family: variable("font-family");
        font-weight: variable("font-weight-regular");
        font-size: variable("font-size-default");
        line-height: 22px;
        color: variable("primary-main");
    }
}

%typography-h1 {
    @include theme {
        font-family: variable("font-family");
        font-weight: variable("font-weight-bold");
        font-size: variable("font-size-large-small");
        line-height: 38px;
        color: variable("primary-main");
    }
}

%typography-h2 {
    @include theme {
        font-family: variable("font-family");
        font-weight: variable("font-weight-bold");
        font-size: variable("font-size-large");
        line-height: 30px;
        color: variable("primary-main");
    }
}

%typography-h3 {
    @include theme {
        font-family: variable("font-family");
        font-weight: variable("font-weight-bold");
        font-size: variable("font-size-medium-large");
        line-height: variable("line-height-single");
    }
}

%typography-h4 {
    @include theme {
        font-family: variable("font-family");
        font-weight: variable("font-weight-bold");
        font-size: variable("font-size-medium");
        line-height: variable("line-height-single");
    }
}

%typography-h5 {
    @include theme {
        font-family: variable("font-family");
        font-weight: variable("font-weight-bold");
        font-size: variable("font-size-medium-small");
        line-height: variable("line-height-single");
    }
}

%typography-h6 {
    @include theme {
        font-family: variable("font-family");
        font-weight: variable("font-weight-light-bold");
        font-size: variable("font-size-default");
        line-height: variable("line-height-single");
    }
}

%typography-subtitle1 {
    @include theme {
        font-family: variable("font-family");
        font-weight: variable("font-weight-regular");
        font-size: variable("font-size-default");
        line-height: 24px;
        color: variable("secondary-main");
    }
}

%typography-subtitle2 {
    @include theme {
        font-family: variable("font-family");
        font-weight: variable("font-weight-regular");
        font-size: variable("font-size-xsmall");
        line-height: 24px;
        color: variable("secondary-main");
    }
}

%typography-subtitle3 {
    @include theme {
        font-family: variable("font-family");
        font-weight: variable("font-weight-regular");
        font-size: variable("font-size-medium-small");
        line-height: 22px;
        color: variable("secondary-main");
    }
}

%anchor {
    @include theme {
        font-family: variable("font-family");
        font-weight: variable("font-weight-bold");
        font-size: variable("font-size-xsmall");
        line-height: 24px;
        color: variable("color-link");
    }
}

%button {
    @include theme {
        font-family: variable("font-family");
        font-weight: variable("font-weight-bold");
        font-size: variable("font-size-default");
        line-height: 22px;
        color: variable("primary-contrast-text");
    }
}

%input-text {
    @include theme {
        font-family: variable("paragraph-font-family");
        line-height: variable("line-height-single");
        font-size: variable("font-size-medium-small");
        font-weight: variable("font-weight-medium");
    }
}

%input-label {
    @include theme {
        font-family: variable("font-family");
        line-height: variable("line-height-single");
        font-size: variable("font-size-medium-small");
        font-weight: variable("font-weight-medium");
    }
}
