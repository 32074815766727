.tos-modal-heading {
    margin-right: 30px;
    @extend %typography-h2;
}

.tos-modal-content {
    @extend %typography-subtitle1;

    @include theme() {
        line-height: 22px;
    }

    li {
        margin-bottom: 8px;
    }

    a {
        text-decoration: none;
        @extend %anchor;

        @include theme() {
            font-size: 16px;
        }
    }
}

.tos-modal-close {
    @extend %typography-h5;

    a {
        text-decoration: none;

        @include theme() {
            color: variable("primary-main");
        }
    }
}

.ach-verified-modal-heading {
    @extend %typography-h2;
    margin-bottom: 20px;
}

.ach-verified-modal-link {
    text-decoration: none;
    @extend %anchor;
}

.ach-verified-modal-sub-heading {
    margin-top: 20px;
    margin-bottom: 20px;
    @extend %typography-subtitle1;
}
