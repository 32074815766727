.error-page-container {
    padding: 149.5px 10.14px;

    @include mobile() {
        padding: 196.5px 16px;
    }

    &__icon {
        display: flex;
        justify-content: center;

        img {
            width: 80px;
            height: 80px;
        }
    }

    &__heading {
        margin-top: 40px;
        display: flex;
        justify-content: center;
        text-align: center;
        @extend %typography-h1;

        @include mobile() {
            margin-top: 36px;

            @include theme() {
                font-size: variable("font-size-large");
                line-height: 30px;
            }
        }
    }

    &__sub-heading {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        text-align: center;
        @extend %typography-subtitle3;

        @include mobile() {
            margin-top: 14px;

            @include theme() {
                font-size: variable("font-size-default");
            }
        }
    }

    &__button {
        margin: 0 auto;
        margin-top: 40px;
        width: 166px;

        @include mobile() {
            margin-top: 36px;
            width: 100%;
        }

        .error-button {
            @extend %button;
        }
    }
}
