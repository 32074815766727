.payment-card-details {
    &__grid-container {
        padding: 40px 32px;

        @include mobile() {
            padding: 40px 16px;
        }

        &__main {
            display: flex;
            flex-direction: column-reverse;
            grid-row-gap: 24px;

            @include mobile() {
                flex-direction: column-reverse;
                grid-row-gap: 16px;
            }

            &__left {
                width: 100%;

                &__heading {
                    margin-bottom: 16px;
                    @extend %typography-h2;

                    @include mobile() {
                        margin-bottom: 16px;
                    }
                }

                &__sub-heading {
                    margin-bottom: 16px;
                    @extend %typography-subtitle1;

                    @include mobile() {
                        margin-bottom: 16px;
                    }
                }

                &__payment-error {
                    margin-bottom: 16px;
                    @include theme() {
                        background: variable("background-color-error");
                        border: 2px solid variable("error-main");
                    }

                    border-radius: 8px;
                    box-sizing: border-box;
                    padding: 8px 16px;

                    p {
                        @extend %typography-p;
                        b {
                            display: block;
                        }
                    }
                }

                &__card-details {
                    margin-top: 12px;
                    border-radius: 0px 32px;
                }

                &__border-top {
                    border-top: 1px solid #c9c7c7;
                }

                &__margin-top {
                    margin-top: 15px;
                }

                &__checkbox-accept {
                    padding-top: 15px;
                    display: flex;
                    align-items: center;

                    @include theme() {
                        font-family: variable("font-family");
                        font-weight: variable("font-weight-regular");
                        font-size: variable("font-size-xsmall");
                        line-height: 24px;
                    }

                    .accept-msg {
                        @include theme() {
                            color: variable("secondary-main");
                        }
                    }

                    .term-of-sales {
                        margin-left: 4px;

                        a {
                            text-decoration: none;
                            @extend %anchor;
                        }
                    }
                }

                &__admin-fee-checkbox {
                    padding-top: 15px;
                    display: flex;

                    @include theme() {
                        font-family: variable("font-family");
                        font-weight: variable("font-weight-regular");
                        font-size: variable("font-size-xsmall");
                        line-height: 24px;
                    }

                    .admin-fee-content {
                        margin-top: -2px;
                        width: 95%;
                    }

                    .accept-msg {
                        @include theme() {
                            color: variable("secondary-main");
                        }
                    }
                }

                &__checkbox-error-msg {
                    @include theme() {
                        font-family: variable("font-family");
                        font-weight: variable("font-weight-medium");
                        font-size: variable("font-size-xsmall");
                        line-height: 24px;
                    }

                    margin-left: 26px;
                    color: #e51e42;
                }

                &__button {
                    margin-top: 24px;
                    width: 100%;
                    @extend %button;

                    @include mobile() {
                        width: 100%;
                        margin-top: 24px;
                    }
                }

                .disabled-submit-button {
                    color: #ffffff;
                    box-shadow: none;
                    background-color: #c9c7c7;

                    &:hover {
                        color: #ffffff;
                        box-shadow: none;
                        background-color: #c9c7c7;
                    }
                }
            }

            &__right {
                width: 100%;

                &__logo {
                    margin-top: 0px;
                    display: flex;
                    grid-column-gap: 20px;
                    align-items: center;

                    @include mobile() {
                        margin-top: 0px;
                    }

                    img {
                        width: 114.12px;
                        height: 43.03px;
                    }
                }
            }
        }

        &__footer {
            margin-top: 24px;

            @include mobile() {
                align-items: center;
                justify-content: center;
                flex-direction: column;
                grid-row-gap: 16px;
            }

            &__left {
                display: flex;
                align-items: center;
                justify-content: center;

                .secured_message {
                    margin: 4px;
                    @extend %typography-subtitle2;

                    @include theme {
                        line-height: 16px;
                    }
                }

                .lock-icon {
                    margin-bottom: 2px;
                }
            }

            &__fetch-branding {
                margin-top: 16px;
                text-align: center;
                @extend %typography-subtitle2;
            }

            &__right {
                margin-top: 16px;
                display: flex;
                justify-content: center;

                .content-line {
                    margin: 0 4px;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 25px;
                    color: #5757f2;
                }

                a {
                    text-decoration: none;
                    @extend %anchor;

                    @include mobile() {
                        @include theme() {
                            font-size: variable("font-size-xsmall");
                        }
                    }
                }
            }
        }
    }
}
