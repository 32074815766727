.component-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.component-card {
    box-shadow: 0px 5px 15px 0px #00000026;
    border-radius: 12px;
    width: 600px;

    @include theme {
        background-color: variable("background-color");
    }

    @include mobile() {
        width: 100%;
    }
}

.loader {
    max-width: 600px;

    @include tablet-only() {
        max-width: 550px;
    }

    @include mobile() {
        max-width: 100%;
    }
}
